import React, { useState } from "react";
import { API } from "shared";
import { message } from "antd";

type CartContextProps = {
  charge: any;
  shipping: any;
  itemNumber: any;
  carpetInfo: any;
  fetchCarpetCart: any;
};

export const CartDataContext = React.createContext<Partial<CartContextProps>>(
  {}
);

const CartContext = (props) => {
  let subTotal = 0;
  const shipping = 0;
  let cartItems = [] as any;

  const [charge, setCharge] = useState(0);
  const [itemNumber, setCartItemsNumber] = useState(0);
  const [carpetInfo, setCarpetInfo] = useState([] as any);

  const fetchCarpetCart = async () => {
    cartItems = localStorage.getItem("cart");
    const carpetData = [];
    const carpetInformation = [];
    const cartData = JSON.parse(cartItems);
    if (cartData.length > 0) {
      for (const carpetId of cartData) {
        try {
          const response = await API.get(`/carpets/${carpetId}`);
          if (response && response?.data) {
            carpetData.push(response?.data?.carpet);
          }
        } catch (err) {
          message.error(err.message);
        }
      }
    }
    if (carpetData.length !== 0) {
      setCartItemsNumber(carpetData.length);
      carpetData.map((ele: any) => {
        carpetInformation.push(ele.id);
        subTotal += ele.price;
        setCharge(subTotal);
        setCarpetInfo(carpetInformation);
      });
    }
  };

  return (
    <CartDataContext.Provider
      value={{ charge, shipping, itemNumber, carpetInfo, fetchCarpetCart }}
    >
      {props.children}
    </CartDataContext.Provider>
  );
};
export { CartContext };
